import React from "react";
import {Product} from "./Product";
import {Space} from 'antd'


export const ProductPage : React.FunctionComponent<any> = ()=>{
    return <React.Fragment>
        <Space style={{width:'100vw', height: 'calc(100vh - 50px)'}} align={"center"}>
            <Product
                title={"test"}
                features={[
                    {
                        name: "Test 1",
                        availability: true
                    },
                    {
                        name: "Test 2",
                        availability: false
                    },
                    {
                        name: "Test 3",
                        availability: true
                    },
                    {
                        name: "Test 4",
                        availability: false
                    },
                    {
                        name: "Test 5",
                        availability: true
                    }
                ]}
            />
            <Product
                title={"test"}
                features={[
                    {
                        name: "Test 1",
                        availability: true
                    },
                    {
                        name: "Test 2",
                        availability: false
                    },
                    {
                        name: "Test 3",
                        availability: true
                    },
                    {
                        name: "Test 4",
                        availability: false
                    },
                    {
                        name: "Test 5",
                        availability: true
                    }
                ]}
            />
        </Space>

    </React.Fragment>
}