import React from "react";
import {Form,Input,Select,Card,Space,Button,notification} from 'antd'
import RcQueueAnim from "rc-queue-anim";


interface ContactUsState {
    data?: any
}

export const ContactUs: React.FunctionComponent<any> = ()=>{

    const [data,setData] = React.useState({} as ContactUsState)

    const [form] = Form.useForm()

    const submit = async (data: any)=>{
        try{
            const res = await fetch('https://api.phantomrndsolutions.com/api/contact',{
                method: 'POST',
                body: JSON.stringify({data})
            })
            const {statusCode = 200, body: {message = "Your inquiry is logged in to the system. we will get back to you within 3 days.\\n Thank You."} = {}}  = await res.json()
            if(statusCode === 200)
                notification.success({message})
            else
                notification.error({message})
        }catch (e){
            notification.error({message: e.message})
        }

        form.resetFields();
        setData({});
    }

    React.useEffect(()=>{
        if(data.data){
            submit(data.data)
        }
    },[data])

    return <React.Fragment>
        <div style={{
            height: 'calc(100vh - 50px)',
            width: '100vw',
            textAlign:'center'
        }}>
            <Space style={{height: `calc(100vh - 50px)`}} align={"center"}>
                <RcQueueAnim
                    key={`desc-anime`}
                    duration={2500}
                    type={['right', 'left']}
                    ease={['easeOutQuart', 'easeInOutQuart']}
                    animConfig={[
                        { opacity: [1, 0], translateY: [0, -100],translateX:[0,-300] },
                        { opacity: [1, 0], translateY: [0, 30],translateX:[0,-200] }
                    ]}
                >
                    {[<Card key={`contact-form`} style={{width: '50vw', margin: 'auto'}}>
                        <Form
                            labelCol={{span: 4}}
                            form={form}

                            onFinish={(values) => {
                                setData({data: values})
                            }}
                        >
                            <Form.Item required rules={[{required:true}]} name={`name`} label={'Name'}>
                                <Input/>
                            </Form.Item>
                            <Form.Item required rules={[{required:true}]} name={`reply_to`} label={`Email`}>
                                <Input/>
                            </Form.Item>
                            <Form.Item required rules={[{required:true}]} name={`purpose`} label={`Reason`}>
                                <Select>
                                    <Select.Option value={`Consultancy`}>Consultancy</Select.Option>
                                    <Select.Option value={`Product Development`}>Product Development</Select.Option>
                                    <Select.Option value={`Product Inquiry`}>Product Inquiry</Select.Option>
                                    <Select.Option value={`Careers`}>Careers</Select.Option>
                                    <Select.Option value={`Other`}>Other</Select.Option>
                                </Select>
                            </Form.Item>
                            <Form.Item required rules={[{required:true}]} name={`message`} label={`Message`}>
                                <Input.TextArea style={{height: "200px"}}/>
                            </Form.Item>
                            <Form.Item style={{marginBottom: 0}}>
                                <Button style={{float: "right"}} htmlType={`submit`} type={`primary`}>Send</Button>
                            </Form.Item>
                        </Form>
                    </Card>]}
                </RcQueueAnim>
            </Space>

        </div>
    </React.Fragment>
}