import React from "react";
import {Card,List} from 'antd'
import {CheckOutlined,CloseOutlined} from "@ant-design/icons";

export interface FeatureProps{
    name:string,
    availability?: boolean
}

export interface ProductProps{
    title: string,
    description?:string
    features:FeatureProps[]
}


export const Product:React.FunctionComponent<ProductProps>  = (props) =>{
    return <React.Fragment>
        <Card
            title={props.title}
            size={'small'}
            style={{width:'calc(100vw / 4)', margin: "auto"}}
        >
            <List
                dataSource={props.features}
                renderItem={(item)=>{
                    return <List.Item
                        actions={[
                            item.availability ? <CheckOutlined style={{color:`#52c41a`}} key={item.name} /> : <CloseOutlined style={{color:`#b50810`}} key={item.name}/>
                        ]}
                    >
                        {item.name}
                    </List.Item>
                }}
            />
        </Card>
    </React.Fragment>
}