import React from 'react';
import {Menu} from "antd";
import 'antd/dist/antd.dark.css'
import {ContactUs} from "./component/ContactUs";
import {Home} from "./component/Home";
import {ProductPage} from "./component/ProductPage";
import {TableControl} from "./component/packages/TableControl";

function App() {
    const [page,setPage] = React.useState(`home`);

  return (
      <div style={{width:'100vw', height:'100vh'}}>
        <div style={{width: '100%', height: '50px'}}>
            <Menu mode={`horizontal`} selectedKeys={[page]} onClick={({key})=>setPage(`${key}`)}>
                <Menu.Item key={`home`}>Home</Menu.Item>
                {/*<Menu.Item key={`products`}>Products</Menu.Item>*/}
                <Menu.SubMenu key={`components`} title={`Components`}>
                    <Menu.Item key={`table`}>Phantom Table Control</Menu.Item>
                </Menu.SubMenu>
                {/*<Menu.Item key={`career`}>Careers</Menu.Item>*/}
                <Menu.Item key={`contact`}>Contact Us</Menu.Item>
            </Menu>
        </div>
          <div style={{width: '100%', height: 'calc(100vh - 50px)',maxHeight: 'calc(100vh - 50px)', overflowY: 'auto'}}>
              {page === "contact" && <ContactUs/>}
              {page === "home" && <Home/>}
              {page === "products" && <ProductPage/>}
              {page === "table" && <TableControl/>}
          </div>
      </div>
  );
}

export default App;
