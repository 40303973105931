import React from "react";
import {Table} from "@phantomit/table";
import {LocalDatasource} from "@phantomit/datasource/lib/local/LocalDatasource";

import moment from "moment";
import {ColumnType} from "@phantomit/datasource/lib/utils/definition";
import {Space,Card,Drawer,Typography,Button} from "antd";
import RcQueueAnim from "rc-queue-anim";
import {CloseOutlined} from '@ant-design/icons'
const description = "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Scelerisque varius morbi enim nunc faucibus a. Morbi tristique senectus et netus et. In metus vulputate eu scelerisque felis imperdiet proin. Laoreet suspendisse interdum consectetur libero id. Malesuada fames ac turpis egestas integer eget. Scelerisque eleifend donec pretium vulputate";


export const TableControl = ()=>{

    const [code,setCode] = React.useState(false)
    const [source] = React.useState(new LocalDatasource())
    const [info,setInfo] = React.useState(true);


    return <div style={{
        height: 'calc(100vh - 50px)',
        width: '100vw',
        position: 'relative',
        textAlign:'center'
    }}>
        <Space style={{height: `calc(100vh - 50px)`}} align={"center"}>

                <RcQueueAnim
                    key={`desc-anime`}
                    duration={2500}
                    type={['right', 'left']}
                    ease={['easeOutQuart', 'easeInOutQuart']}
                    animConfig={[
                        { opacity: [1, 0], translateY: [0, -100],translateX:[0,100] }
                    ]}
                >
                    {[
                        <Card
                            key={'phantom-table'} style={{height: `calc(100vh - 120px)`, width: '80vw'}}>
                        <Table
                        source={source}
                        fetch={async () => {
                            if(source.size() > 0 )
                                await new Promise(r=>setTimeout(r,1000))
                            return Array(50).fill(0).map((value, index) => ({
                                name: `Name : ${index}`,
                                title: `Title : ${index}`,
                                qty: Math.ceil(Math.random() * 1000),
                                state: index % 2 === 0,
                                date: moment().format("yyyy-MM-DD")
                            }))
                        }}
                        save={async (row) => {
                            return row;
                        }}
                        delete={async (row) => {
                            return row;
                        }}
                        footer={<div style={{width:'calc(80vw - 50px)'}}><Button
                            style={{float: 'right'}}
                            onClick={()=>{setCode(true)}}
                            type={`primary`}>View Code</Button></div>}
                        columns={async () => {
                            return [
                                {
                                    title: "Test 1",
                                    dataIndex: "name",
                                    type: ColumnType.string,
                                    visible: true,
                                    filter: true,
                                    schemaIndex: 0,
                                    editable: true,
                                    width: 320
                                },
                                {
                                    title: "Test 2",
                                    dataIndex: "title",
                                    type: ColumnType.string,
                                    visible: true,
                                    sorter: true,
                                    filter: true,
                                    schemaIndex: 1,
                                    editable: true,
                                    width: 320
                                },
                                {
                                    title: "Test 3",
                                    dataIndex: "qty",
                                    type: ColumnType.int,
                                    visible: true,
                                    sorter: true,
                                    filter: true,
                                    schemaIndex: 2,
                                    editable: true,
                                    width: 320
                                },
                                {
                                    title: "Test 4",
                                    dataIndex: "state",
                                    type: ColumnType.boolean,
                                    visible: true,
                                    filter: true,
                                    schemaIndex: 3,
                                    editable: true,
                                    width: 320
                                },
                                {
                                    title: "Test 4",
                                    dataIndex: "date",
                                    type: ColumnType.date,
                                    dateOut: "yyyy-MM-DD",
                                    visible: true,
                                    filter: true,
                                    schemaIndex: 4,
                                    editable: true,
                                    width: 320
                                }
                            ]
                        }}
                        saveColumn={async (columns) => {
                            return columns;
                        }}
                        title={`Phantom Table`}
                        scrollY={`calc(100vh - 275px)`}
                        header={true}
                        actions={{
                            width: 160,
                            refresh: true,
                            add: true,
                            search: true,
                            settings: true,
                            export: true,
                            delete: true,
                            edit: true,
                            select: true,
                            import: true
                        }}
                    />
                        </Card>
                    ]}
                </RcQueueAnim>
        </Space>

        {info && <RcQueueAnim
            key={`text-anime`}
            type={['right', 'left']}
            ease={['easeOutQuart', 'easeInOutQuart']}
            duration={2500}
            animConfig={[
                {opacity: [1, 0], translateY: [0, -100]}
            ]}
        >
            {[
                <div key='context' style={{
                    left: 'calc(100vw / 4)',
                    top: 'calc(100vh / 4)',
                    position: 'absolute',
                    padding: 25,
                    background: 'rgba(0,0,0,0.8)'
                }}>
                    <Button
                        style={{top: 0, right: 0}}
                        icon={<CloseOutlined/>}
                        onClick={()=>{setInfo(false)}}
                    />
                    <Typography.Title level={3}>Phantom Table Control</Typography.Title>
                    <RcQueueAnim
                        key={`desc-anime`}
                        duration={2500}
                        type={['right', 'left']}
                        ease={['easeOutQuart', 'easeInOutQuart']}
                        animConfig={[
                            {opacity: [1, 0], translateY: [0, -200], translateX: [0, -100]},
                            {opacity: [1, 0], translateY: [0, 30], translateX: [0, -100]}
                        ]}
                    >
                        {[<div key={`desc-key`} style={{wordWrap: "break-word", width: '40vw'}}>
                            <Typography.Text>
                                {description}
                            </Typography.Text>
                        </div>]}
                    </RcQueueAnim>
                </div>]}
        </RcQueueAnim>}
        <Drawer
            visible={code}
            width='60vw'
            onClose={()=>setCode(false)}
            title={`Phantom Table Example Code`}
        >

            <Typography.Title level={5}>Repository Configurations</Typography.Title>
            <pre>
                <code style={{fontFamily:'monospace'}}>{`
@phantomit:registry=https://gitlab.com/api/v4/packages/npm/
//gitlab.com/api/v4/packages/npm/:_authToken=CU2fyqrNqzDPvW42rxfZ
                `}</code>
            </pre>
            <Typography.Title level={5}>Installation</Typography.Title>
            <pre>
                <code>{`
npm install @phantomit/datasource @phantomit/table //components
npm install moment papaparse antd @ant-design/icons //dependencies
                `}</code>
            </pre>

            <Typography.Title level={5}>Example Code</Typography.Title>
            <pre>
                <code>
{`
  <Table
    source={new LocalDatasource()}
    fetch={async () => {
        return Array(50).fill(0).map((value, index) => ({
            name: \`Name : \${index}\`,
            title: \`Title : \${index}\`,
            qty: Math.ceil(Math.random() * 1000),
            state: index % 2 === 0,
            date: moment().format("yyyy-MM-DD")
        }))
    }}
    save={async (row) => {
        return row;
    }}
    delete={async (row) => {
        return row;
    }}
    columns={async () => {
        return [
            {
                title: "Test 1",
                dataIndex: "name",
                type: ColumnType.string,
                visible: true,
                filter: true,
                schemaIndex: 0,
                editable: true,
                width: 320
            },
            {
                title: "Test 2",
                dataIndex: "title",
                type: ColumnType.string,
                visible: true,
                sorter: true,
                filter: true,
                schemaIndex: 1,
                editable: true,
                width: 320
            },
            {
                title: "Test 3",
                dataIndex: "qty",
                type: ColumnType.int,
                visible: true,
                sorter: true,
                filter: true,
                schemaIndex: 2,
                editable: true,
                width: 320
            },
            {
                title: "Test 4",
                dataIndex: "state",
                type: ColumnType.boolean,
                visible: true,
                filter: true,
                schemaIndex: 3,
                editable: true,
                width: 320
            },
            {
                title: "Test 4",
                dataIndex: "date",
                type: ColumnType.date,
                dateOut: "yyyy-MM-DD",
                visible: true,
                filter: true,
                schemaIndex: 4,
                editable: true,
                width: 320
            }
        ]
    }}
    saveColumn={async (columns) => {
        return columns;
    }}
    title={\`Phantom Table\`}
    scrollY={\`calc(100vh - 250px)\`}
    header={true}
    actions={{
        width: 160,
        refresh: true,
        add: true,
        search: true,
        settings: true,
        export: true,
        delete: true,
        edit: true,
        select: true,
        import: true
    }}
/>
`}
                    </code>
                </pre>
        </Drawer>
    </div>
}

