import React from "react";
import {Typography} from 'antd'
import RcQueueAnim from "rc-queue-anim";


export interface ImageProps{
    src: string,
    align: "left" | "right"
    title: string,
    description: string,
    animate?: boolean
}

export const Image : React.FunctionComponent<ImageProps> = (props) => {
    return <React.Fragment>
        <div style={{width: '100vw',height: 'calc(100vh - 90px)',position:'relative'}}>


            {props.animate &&
            <RcQueueAnim
                type={['right', 'left']}
                ease={['easeOutQuart', 'easeInOutQuart']}
                duration={2500}
                animConfig={[
                    { opacity: [1, 0] }
                ]}
            >
                {[
                    <img key='img' src={props.src} alt={``} style={{float: props.align,height: 'calc(100vh - 50px)'}}/>,
                    <RcQueueAnim
                        key={`text-anime`}
                        type={['right', 'left']}
                        ease={['easeOutQuart', 'easeInOutQuart']}
                        duration={2500}
                        animConfig={[
                            { opacity: [1, 0], translateY: [0, 200] },
                            { opacity: [1, 0], translateY: [0, -200] }
                        ]}
                    >
                        {[
                            <div key='context' style={{
                                float: props.align === "right" ? "left" : "right",
                                left: props.align === "right" ? 10 : undefined,
                                right: props.align === "left" ? 20 : undefined,
                                top: 'calc(100vh / 4)',
                                bottom:20,
                                position: 'absolute',
                                padding: 25,
                            }}>
                                <Typography.Title>{props.title}</Typography.Title>
                                <RcQueueAnim
                                    key={`desc-anime`}
                                    duration={2500}
                                    type={['right', 'left']}
                                    ease={['easeOutQuart', 'easeInOutQuart']}
                                    animConfig={[
                                        { opacity: [1, 0], translateY: [0, -300],translateX:[0,200] },
                                        { opacity: [1, 0], translateY: [0, 30],translateX:[0,-200] }
                                    ]}
                                >
                                    {[<div key={`desc-key`} style={{wordWrap: "break-word", width: '40vw'}}>
                                        <Typography.Text>
                                            {props.description}
                                        </Typography.Text>
                                    </div>]}
                                </RcQueueAnim>
                            </div>]}
                    </RcQueueAnim>
                ]}
            </RcQueueAnim>
            }
        </div>
    </React.Fragment>
}