import React from "react";
import { Carousel } from 'antd';
import {Image} from "./Image";
const description = "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Scelerisque varius morbi enim nunc faucibus a. Morbi tristique senectus et netus et. In metus vulputate eu scelerisque felis imperdiet proin. Laoreet suspendisse interdum consectetur libero id. Malesuada fames ac turpis egestas integer eget. Scelerisque eleifend donec pretium vulputate";


export const Home: React.FunctionComponent<any> = ()=>{

    const [active,setActive] = React.useState(0)

    return <React.Fragment>
        <div>
                <Carousel
                    style={{margin:"auto"}}
                    autoplay={true}
                    initialSlide={0}
                    lazyLoad="ondemand"
                    dots={false}
                    draggable={true}
                    swipe={true}
                    afterChange={(current)=>{
                        setActive(current)
                    }}
                >
                    <div >
                        {active === 0 && <Image
                            src="images/home/pexels-josh-sorenson-1714208.jpg"
                            align={`left`}
                            title={'We Dare'}
                            description={description}
                            animate
                        />}
                    </div>
                    <div>
                        {active === 1 && <Image
                            src="images/home/pexels-kevin-ku-577585.jpg"
                            align={`right`}
                            title={'We Dare'}
                            description={description}
                            animate
                        />}
                    </div>
                    <div>
                        {active === 2 && <Image
                            src="images/home/pexels-luis-gomes-546819.jpg"
                            align={`left`}
                            title={'We Dare'}
                            description={description}
                            animate
                        />}
                    </div>
                    <div>
                        {active === 3 && <Image
                            src="images/home/pexels-markus-spiske-177598.jpg"
                            align={`right`}
                            title={'We Dare'}
                            description={description}
                            animate
                        />}
                    </div>
                    <div>
                        {active === 4 && <Image
                            src="images/home/pexels-pixabay-38568.jpg"
                            align={`left`}
                            title={'We Dare'}
                            description={description}
                            animate
                        />}
                    </div>
                    <div>
                        {active === 5 && <Image
                            src="images/home/pexels-xxss-is-back-777001.jpg"
                            align={`right`}
                            title={'We Dare'}
                            description={description}
                            animate
                        />}
                    </div>
                </Carousel>
        </div>

    </React.Fragment>
}